<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="secondary"
          class="text-transform-none"
          rounded
          outlined
          :large="large"
          :small="small"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left>
            {{ icons.mdiEthereum }}
          </v-icon>
          <identicon v-if="wallet" :wallet="wallet" class="identicon"></identicon>
          <span class="d-none d-sm-block">
            {{ walletTextLong() }}
          </span>
          <span class="d-sm-none">
            {{ walletTextShort() }}
          </span>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span v-if="wallet">Connected Wallet</span>
          <span v-if="!wallet">Connect Wallet</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="ethereum && wallet" class="text-center">
          <v-avatar size="50px" class="mt-n1 mr-2">
            <img :src="require('@/assets/wallets/MetaMask.svg')">
          </v-avatar>
          <span class="text-h6">MetaMask</span>
          <account :account="wallet" class="px-6 my-4" large></account>
          <p>To disconnect use the MetaMask user interface.</p>
        </v-card-text>
        <v-card-text v-if="ethereum && !wallet" class="text-center">
          <v-btn
            rounded
            outlined
            class="text-transform-none mb-4"
            @click="connectWallet"
          >
            <v-avatar size="30px" class="mr-2">
              <img :src="require('@/assets/wallets/MetaMask.svg')">
            </v-avatar>
            MetaMask
          </v-btn>
        </v-card-text>
        <v-card-text v-if="!ethereum" class="text-center">
          <p>You have not installed a wallet yet.</p>
          <v-btn
            rounded
            outlined
            class="text-transform-none mb-4"
            href="https://metamask.io/"
            target="_blank"
          >
            <v-avatar size="30px" class="mr-2">
              <img :src="require('@/assets/wallets/MetaMask.svg')">
            </v-avatar>
            Install MetaMask
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-chip
      v-if="chainWarning()"
      color="warning"
      class="d-none d-md-inline-flex ms-4"
      :large="large"
      :small="small"
    >
      {{ chainText() }}
    </v-chip>
  </div>
</template>

<script>
import { mdiEthereum, mdiClose } from '@mdi/js'
import Web3 from 'web3'
import Identicon from './Identicon.vue'
import Account from './Account.vue'

export default {
  components: {
    Identicon,
    Account,
  },
  props: {
    warning: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      icons: {
        mdiEthereum,
        mdiClose,
      },
    }
  },
  data() {
    return {
      ethereum: false,
      chainId: undefined,
      wallet: undefined,
      dialog: false,
    }
  },
  beforeMount() {
    this.getChain()
    this.getWallet()
  },
  methods: {
    getChain() {
      if (window.ethereum) {
        this.ethereum = true
        window.ethereum.request({
          method: 'eth_chainId',
        }).then((chainId) => {
          this.chainId = chainId
          this.$store.commit('chainId', this.chainId)

          window.ethereum.on('chainChanged', (chainIdChanged) => {
            this.chainId = chainIdChanged
            this.$store.commit('chainId', this.chainId)
          })
        }).catch((error) => {
          this.$store.commit('alertType', 'error')
          this.$store.commit('alertText', error.message)
        })
      }
    },
    getWallet() {
      if (window.ethereum) {
        window.ethereum.request({
          method: 'eth_accounts',
          params: [{}],
        }).then((accounts) => {
          this.wallet = Web3.utils.toChecksumAddress(accounts[0])
          this.$store.commit('wallet', this.wallet)

          window.ethereum.on('accountsChanged', (accounts) => {
            this.wallet = Web3.utils.toChecksumAddress(accounts[0])
            this.$store.commit('wallet', this.wallet)
          })
        }).catch((error) => {
          this.$store.commit('alertType', 'error')
          this.$store.commit('alertText', error.message)
        })
      }
    },
    connectWallet() {
      if (window.ethereum) {
        if (!this.wallet) {
          window.ethereum.request({
            method: 'eth_requestAccounts',
            params: [{}],
          }).then((accounts) => {
            this.wallet = Web3.utils.toChecksumAddress(accounts[0])
            this.dialog = false
          }).catch((error) => {
            this.$store.commit('alertType', 'error')
            this.$store.commit('alertText', error.message)
          })
        }
      }
    },
    chainText() {
      var text = 'No Network'
      if (this.chainId === '0x1') {
        text = 'Main Network'
      } else if (this.chainId === '0x3') {
        text = 'Ropsten Test Network'
      } else if (this.chainId === '0x4') {
        text = 'Rinkeby Test Network'
      } else if (this.chainId === '0x5') {
        text = 'Goerli Test Network'
      } else if (this.chainId === '0x2a') {
        text = 'Kovan Test Network'
      } else {
        text = 'Unknown Network'
      }

      return text
    },
    chainWarning() {
      return this.warning && this.chainId !== undefined && this.chainId !== '0x1'
    },
    walletTextLong() {
      var text = 'Connect Wallet'
      if (this.wallet) {
        text = this.wallet.slice(0, 6) + '...' + this.wallet.slice(-4)
      }

      return text
    },
    walletTextShort() {
      var text = 'Connect'
      if (this.wallet) {
        text = this.wallet.slice(0, 4) + '...' + this.wallet.slice(-2)
      }

      return text
    },
  },
}
</script>

<style scoped>
.identicon {
  width: 18px !important;
}
.text-transform-none {
  text-transform: none;
}
</style>
