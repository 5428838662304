import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/projects/Projects.vue'),
    meta: {
      title: 'Dashboard',
    },
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/projects/Projects.vue'),
    meta: {
      title: 'Projects',
    },
  },
  {
    path: '/projects/:slug',
    name: 'project',
    component: () => import('@/views/projects/Project.vue'),
    meta: {
      title: 'Project',
    },
  },
  {
    path: '/investments',
    name: 'investments',
    component: () => import('@/views/investments/Investments.vue'),
    meta: {
      title: 'My Investments',
    },
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('@/views/transactions/Transactions.vue'),
    meta: {
      title: 'My Transactions',
    },
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/reports/Summary.vue'),
    meta: {
      title: 'Reports',
    },
  },
  {
    path: '/reports/:slug',
    name: 'report',
    component: () => import('@/views/reports/Project.vue'),
    meta: {
      title: 'Report',
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/notifications/Notifications.vue'),
    meta: {
      title: 'Notifications',
      requiresAuth: true,
    },
  },
  {
    path: '/calculator/:slug?',
    name: 'calculator',
    component: () => import('@/views/calculator/Calculator.vue'),
    meta: {
      title: 'Solar Investment Calculator',
    },
  },
  {
    path: '/invest/:slug',
    name: 'invest',
    component: () => import('@/views/invest/Invest.vue'),
    meta: {
      title: 'Invest',
    },
  },
  {
    path: '/distribute/:slug',
    name: 'distribute',
    component: () => import('@/views/distribute/Distribute.vue'),
    meta: {
      title: 'Distribute',
    },
  },
  {
    path: '/flip/:slug',
    name: 'flip',
    component: () => import('@/views/flip/Flip.vue'),
    meta: {
      title: 'Flip',
    },
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/views/support/Support.vue'),
    meta: {
      title: 'Support',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/auth/Profile.vue'),
    meta: {
      title: 'Profile',
      requiresAuth: true,
    },
  },
  {
    path: '/verification',
    name: 'verification',
    component: () => import('@/views/verification/Verification.vue'),
    meta: {
      title: 'Profile Verification',
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      title: 'Log In',
      layout: 'blank',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      title: 'Register',
      layout: 'blank',
    },
  },
  {
    path: '/log-in',
    name: 'log-in',
    component: () => import('@/views/auth/Social.vue'),
    meta: {
      title: 'Sign In',
      layout: 'blank',
    },
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import('@/views/auth/Social.vue'),
    meta: {
      title: 'Sign Up',
      layout: 'blank',
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/Error.vue'),
    meta: {
      title: '404 Not Found',
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: '404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  function checkAuth() {
    if (to.meta.requiresAuth && !router.app.$auth.isAuthenticated) {
      next({ name: 'log-in' })
    } else {
      next()
    }
  }

  if (!router.app.$auth.loading) {
    checkAuth()
  } else {
    router.app.$auth.$watch('loading', (loading) => {
      if (loading === false) {
        checkAuth()
      }
    })
  }
})

router.afterEach((to, from) => {
  document.title = (to.meta.title || 'Projects') + ' | Regent Renewables'
})

export default router
