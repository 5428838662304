const data = [
  {
    env: 'production',
    chain_id: '0x89',
    address: '0xd0AE89301eddF0E1652984336e6f83C1591A34BA',
    image: '/projects/images/the-daly-house-2.jpg',
    investorPack: '',
    investmentAgreement: '',
    status: 4,
    connectionDate: new Date('11/01/2022'),
    inverterID: '30415401',
    energyGenerated: null,
    carbonFactor: 0.97,
    title: 'The Daly House',
    slug: 'the-daly-house',
    tokenName: 'The Daly House',
    tokenSymbol: 'TDH',
    stablecoinName: 'USD Coin',
    stablecoinSymbol: 'USDC',
    stablecoinAddress: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    type: 'Residential Rooftop',
    country: 'SA',
    region: 'Cape Town',
    capacity: '7.2kW',
    lifetime: 25,
    cost: 8400,
    percentageSold: 0,
    investmentFee: 1.5,
    distributionFee: 0.5,
    contractType: 'Sale Leaseback',
    TEPPreFlipShare: 100,
    TEPPostFlipShare: 100,
    TEPInvestmentShare: 100,
    bonusDepreciation: false,
    IRR: 13.51,
    avgAnnualkWh: 4775,
    tarrif: 0.1047,
    escalationFactor: 0.1,
    description: 'This is our 1st prototype project to test the technical aspects of the DeFi Platform, from share issue to disbursements.',
  },
  {
    env: 'staging',
    chain_id: '0x13881',
    address: '0x3C65C59b2111f328a97a0B33e996511075B17bFB',
    image: '/projects/images/the-daly-house-2.jpg',
    investorPack: '',
    investmentAgreement: '',
    status: 4,
    connectionDate: new Date('11/01/2022'),
    inverterID: '30415401',
    energyGenerated: null,
    carbonFactor: 0.97,
    title: 'The Daly House',
    slug: 'the-daly-house',
    tokenName: 'The Daly House',
    tokenSymbol: 'TDH',
    stablecoinName: 'USD Coin',
    stablecoinSymbol: 'USDC',
    stablecoinAddress: '0x52c20Eadc7cD6F51970007da30aa1257186eF0C2',
    type: 'Residential Rooftop',
    country: 'SA',
    region: 'Cape Town',
    capacity: '7.2kW',
    lifetime: 25,
    cost: 8400,
    percentageSold: 0,
    investmentFee: 1.5,
    distributionFee: 0.5,
    contractType: 'Sale Leaseback',
    TEPPreFlipShare: 100,
    TEPPostFlipShare: 100,
    TEPInvestmentShare: 100,
    bonusDepreciation: false,
    IRR: 13.51,
    avgAnnualkWh: 4775,
    tarrif: 0.1047,
    escalationFactor: 0.1,
    description: 'This is our 1st prototype project to test the technical aspects of the DeFi Platform, from share issue to disbursements.',
  },
  {
    env: 'development',
    chain_id: '0x539',
    address: '0x9f2388768f89dE25Aa7d59E82D2770A05EA56aa1',
    image: '/projects/images/the-daly-house-2.jpg',
    investorPack: '',
    investmentAgreement: '',
    status: 4,
    connectionDate: new Date('11/01/2022'),
    inverterID: '30415401',
    energyGenerated: null,
    carbonFactor: 0.97,
    title: 'The Daly House',
    slug: 'the-daly-house',
    tokenName: 'The Daly House',
    tokenSymbol: 'TDH',
    stablecoinName: 'USD Coin',
    stablecoinSymbol: 'USDC',
    stablecoinAddress: '0xd0AE89301eddF0E1652984336e6f83C1591A34BA',
    type: 'Residential Rooftop',
    country: 'SA',
    region: 'Cape Town',
    capacity: '7.2kW',
    lifetime: 25,
    cost: 8400,
    percentageSold: 0,
    investmentFee: 1.5,
    distributionFee: 0.5,
    contractType: 'Sale Leaseback',
    TEPPreFlipShare: 100,
    TEPPostFlipShare: 100,
    TEPInvestmentShare: 100,
    bonusDepreciation: false,
    IRR: 13.51,
    avgAnnualkWh: 4775,
    tarrif: 0.1047,
    escalationFactor: 0.1,
    description: 'This is our 1st prototype project to test the technical aspects of the DeFi Platform, from share issue to disbursements.',
  },
  {
    env: 'staging',
    chain_id: '0x3',
    address: '0xF5855C5D6d7EadFBb67fC44e0484c69acaBeD0b5',
    image: '/projects/images/project-1.jpeg',
    investorPack: '/projects/files/Investor Pack - Karoo Fresh.pdf',
    investmentAgreement: '/projects/files/Stockholders Agreement - Karoo Fresh.pdf',
    status: 3,
    connectionDate: new Date('11/14/2016'),
    inverterID: '',
    energyGenerated: null,
    carbonFactor: 0.93,
    title: 'Sale Leaseback Test',
    slug: 'sale-leaseback-test',
    tokenName: 'Sale Leaseback Test',
    tokenSymbol: 'SLT',
    stablecoinName: 'USD Coin',
    stablecoinSymbol: 'USDC',
    stablecoinAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    type: 'Commercial Rooftop',
    country: 'USA',
    region: 'New York',
    capacity: '150kW',
    lifetime: 25,
    cost: 250000,
    percentageSold: 64,
    investmentFee: 1.5,
    distributionFee: 0.5,
    contractType: 'Sale Leaseback',
    TEPPreFlipShare: 100,
    TEPPostFlipShare: 100,
    TEPInvestmentShare: 100,
    bonusDepreciation: false,
    IRR: 8.65,
    avgAnnualkWh: 46200,
    tarrif: 0.05,
    escalationFactor: 0.1,
    description: 'This is a demo project that is only used for testing platform features on the staging environment.',
  },
  {
    env: 'development',
    chain_id: '0x539',
    address: '0x6b4c8232aC4702D8728e8A2F15D659c0C56aa72c',
    image: '/projects/images/project-1.jpeg',
    investorPack: '/projects/files/Investor Pack - Karoo Fresh.pdf',
    investmentAgreement: '/projects/files/Stockholders Agreement - Karoo Fresh.pdf',
    status: 3,
    connectionDate: new Date('11/14/2016'),
    inverterID: '',
    energyGenerated: null,
    carbonFactor: 0.93,
    title: 'Sale Leaseback Test',
    slug: 'sale-leaseback-test',
    tokenName: 'Sale Leaseback Test',
    tokenSymbol: 'SLT',
    stablecoinName: 'USD Coin',
    stablecoinSymbol: 'USDC',
    stablecoinAddress: '0x0E91F27D24C13C908d80E5254AdB1f6137dC4662',
    type: 'Commercial Rooftop',
    country: 'USA',
    region: 'New York',
    capacity: '150kW',
    lifetime: 25,
    cost: 250000,
    percentageSold: 64,
    investmentFee: 1.5,
    distributionFee: 0.5,
    contractType: 'Sale Leaseback',
    TEPPreFlipShare: 100,
    TEPPostFlipShare: 100,
    TEPInvestmentShare: 100,
    bonusDepreciation: false,
    IRR: 8.65,
    avgAnnualkWh: 46200,
    tarrif: 0.05,
    escalationFactor: 0.1,
    description: 'This is a demo project that is only used for testing platform features on the staging environment.',
  },
  {
    env: 'staging',
    chain_id: '0x3',
    address: '0xdDE74B0f75C120c0E3d3B4e67a685aE1111e3aE0',
    image: '/projects/images/project-2.jpeg',
    investorPack: '/projects/files/Investor Pack - Karoo Fresh.pdf',
    investmentAgreement: '/projects/files/Stockholders Agreement - Karoo Fresh.pdf',
    status: 2,
    connectionDate: new Date('11/14/2016'),
    inverterID: '',
    energyGenerated: null,
    carbonFactor: 0.93,
    title: 'Partnership Flip Test',
    slug: 'partnership-flip-test',
    tokenName: 'Partnership Flip Test',
    tokenSymbol: 'PFT',
    stablecoinName: 'USD Coin',
    stablecoinSymbol: 'USDC',
    stablecoinAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    type: 'Ground Mount',
    country: 'USA',
    region: 'New Jersey',
    capacity: '500kW',
    lifetime: 25,
    cost: 935000,
    percentageSold: 64,
    investmentFee: 1.5,
    distributionFee: 0.5,
    contractType: 'Partnership Flip',
    TEPPreFlipShare: 95,
    TEPPostFlipShare: 5,
    TEPInvestmentShare: 35,
    bonusDepreciation: false,
    IRR: 9.28,
    avgAnnualkWh: 172800,
    tarrif: 0.05,
    escalationFactor: 0.1,
    description: 'This is a demo project that is only used for testing platform features on the staging environment.',
  },
  {
    env: 'development',
    chain_id: '0x539',
    address: '0x0Caa67cc2303D3B5Dfc7dCe50acBaafdF44C3Bc1',
    image: '/projects/images/project-2.jpeg',
    investorPack: '/projects/files/Investor Pack - Karoo Fresh.pdf',
    investmentAgreement: '/projects/files/Stockholders Agreement - Karoo Fresh.pdf',
    status: 2,
    connectionDate: new Date('11/14/2016'),
    inverterID: '',
    energyGenerated: null,
    carbonFactor: 0.93,
    title: 'Partnership Flip Test',
    slug: 'partnership-flip-test',
    tokenName: 'Partnership Flip Test',
    tokenSymbol: 'PFT',
    stablecoinName: 'USD Coin',
    stablecoinSymbol: 'USDC',
    stablecoinAddress: '0x0E91F27D24C13C908d80E5254AdB1f6137dC4662',
    type: 'Ground Mount',
    country: 'USA',
    region: 'New Jersey',
    capacity: '500kW',
    lifetime: 25,
    cost: 935000,
    percentageSold: 64,
    investmentFee: 1.5,
    distributionFee: 0.5,
    contractType: 'Partnership Flip',
    TEPPreFlipShare: 95,
    TEPPostFlipShare: 5,
    TEPInvestmentShare: 35,
    bonusDepreciation: false,
    IRR: 9.28,
    avgAnnualkWh: 172800,
    tarrif: 0.05,
    escalationFactor: 0.1,
    description: 'This is a demo project that is only used for testing platform features on the staging environment.',
  },
  {
    env: 'staging',
    chain_id: '0x3',
    address: '0x7EC7495a493a0643bB57F5a54ae646022f790a05',
    image: '/projects/images/project-3.jpeg',
    investorPack: '/projects/files/Investor Pack - Karoo Fresh.pdf',
    investmentAgreement: '/projects/files/Stockholders Agreement - Karoo Fresh.pdf',
    status: 1,
    connectionDate: new Date('11/14/2016'),
    inverterID: '',
    energyGenerated: null,
    carbonFactor: 0.93,
    title: 'Big Partnership Flip Test',
    slug: 'big-partnership-flip-test',
    tokenName: 'Big Partnership Flip Test',
    tokenSymbol: 'BPFT',
    stablecoinName: 'USD Coin',
    stablecoinSymbol: 'USDC',
    stablecoinAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    type: 'Ground Mount',
    country: 'USA',
    region: 'New Jersey',
    capacity: '2.7MW',
    lifetime: 25,
    cost: 3700000,
    percentageSold: 64,
    investmentFee: 1.5,
    distributionFee: 0.5,
    contractType: 'Partnership Flip',
    TEPPreFlipShare: 95,
    TEPPostFlipShare: 5,
    TEPInvestmentShare: 35,
    bonusDepreciation: true,
    IRR: 9.61,
    avgAnnualkWh: 683800,
    tarrif: 0.05,
    escalationFactor: 0.1,
    description: 'This is a demo project that is only used for testing platform features on the staging environment.',
  },
  {
    env: 'development',
    chain_id: '0x539',
    address: '0xDda3F6DEe3453d050852577ECcd3517D3655D72C',
    image: '/projects/images/project-3.jpeg',
    investorPack: '/projects/files/Investor Pack - Karoo Fresh.pdf',
    investmentAgreement: '/projects/files/Stockholders Agreement - Karoo Fresh.pdf',
    status: 1,
    connectionDate: new Date('11/14/2016'),
    inverterID: '',
    energyGenerated: null,
    carbonFactor: 0.93,
    title: 'Big Partnership Flip Test',
    slug: 'big-partnership-flip-test',
    tokenName: 'Big Partnership Flip Test',
    tokenSymbol: 'BPFT',
    stablecoinName: 'USD Coin',
    stablecoinSymbol: 'USDC',
    stablecoinAddress: '0x0E91F27D24C13C908d80E5254AdB1f6137dC4662',
    type: 'Ground Mount',
    country: 'USA',
    region: 'New Jersey',
    capacity: '2.7MW',
    lifetime: 25,
    cost: 3700000,
    percentageSold: 64,
    investmentFee: 1.5,
    distributionFee: 0.5,
    contractType: 'Partnership Flip',
    TEPPreFlipShare: 95,
    TEPPostFlipShare: 5,
    TEPInvestmentShare: 35,
    bonusDepreciation: true,
    IRR: 9.61,
    avgAnnualkWh: 683800,
    tarrif: 0.05,
    escalationFactor: 0.1,
    description: 'This is a demo project that is only used for testing platform features on the staging environment.',
  },
]

export default data
