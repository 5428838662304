<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require(`@/assets/images/logo-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
          max-height="150px"
          max-width="200px"
          alt="Regent Renewables"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="nav-menu-items pr-5"
    >
      <nav-menu-link
        title="Projects"
        to="/"
        :icon="icons.mdiHome"
      ></nav-menu-link>
      <!-- <nav-menu-link
        title="Projects"
        :to="{ name: 'projects'}"
        :icon="icons.mdiViewGrid"
      ></nav-menu-link> -->
      <!-- <nav-menu-link
        title="Performance"
        :to="{ name: 'performance'}"
        :icon="icons.mdiChartBar"
      ></nav-menu-link> -->
      <nav-menu-link
        v-if="$store.getters.wallet"
        title="My Investments"
        :to="{ name: 'investments'}"
        :icon="icons.mdiChartBubble"
      ></nav-menu-link>
      <nav-menu-link
        v-if="$store.getters.wallet"
        title="My Transactions"
        :to="{ name: 'transactions'}"
        :icon="icons.mdiFormatListBulleted"
      ></nav-menu-link>
      <nav-menu-link
        title="Calculator"
        :to="{ name: 'calculator'}"
        :icon="icons.mdiCalculator"
      ></nav-menu-link>
      <nav-menu-link
        title="FAQs"
        :to="{ name: 'support'}"
        :icon="icons.mdiHelpCircle"
      ></nav-menu-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHome,
  mdiViewGrid,
  mdiFormatListBulleted,
  mdiHelpCircle,
  mdiChartBubble,
  mdiChartBar,
  mdiCalculator,
} from '@mdi/js'
import NavMenuLink from './NavMenuLink.vue'

export default {
  components: {
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiHome,
        mdiViewGrid,
        mdiFormatListBulleted,
        mdiHelpCircle,
        mdiChartBubble,
        mdiChartBar,
        mdiCalculator,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
