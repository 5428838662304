<template>
  <v-app>
    <nav-menu :is-drawer-open.sync="isDrawerOpen"></nav-menu>

    <v-app-bar
      app
      flat
      absolute
      height="80"
      color="transparent"
    >
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon
            class="d-block d-lg-none me-2"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>

          <wallet></wallet>

          <v-spacer></v-spacer>

          <!-- Right Content -->
          <theme-switcher></theme-switcher>
          <v-btn v-if="!$auth.isAuthenticated && !$auth.loading" rounded outlined class="ms-3" color="secondary" :to="{ name: 'log-in' }">
            Log In
          </v-btn>
          <v-btn v-if="!$auth.isAuthenticated && !$auth.loading" rounded class="ms-3" color="primary" :to="{ name: 'sign-up' }">
            Sign Up
          </v-btn>
          <v-btn
            v-if="$auth.isAuthenticated"
            icon
            small
            class="mx-3"
            :to="{ name: 'notifications' }"
          >
            <v-icon>
              {{ icons.mdiBellOutline }}
            </v-icon>
          </v-btn>
          <v-btn v-if="!$auth.isAuthenticated && $auth.loading" loading fab small></v-btn>
          <user-menu v-if="$auth.isAuthenticated"></user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <v-container fluid class="px-6 py-0">
        <v-alert
          v-model="alertVisible"
          prominent
          outlined
          text
          dismissible
          :type="$store.getters.alertType"
        >
          {{ $store.getters.alertText }}
        </v-alert>
        <v-card color="background_light">
          <v-card-text>
            <slot></slot>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>

    <v-footer
      app
      inset
      color="transparent"
      absolute
      height="60"
      class="px-0"
    >
      <div class="mx-6 d-flex justify-center justify-sm-space-between w-full text-body-2">
        <span>
          © {{ new Date().getFullYear() }}
          <a href="https://regentrenewables.com" target="_blank" class="text-decoration-none text--secondary">
            Regent Renewables
          </a>
        </span>
        <span>
          <a href="https://regentrenewables.com/privacy-policy" target="_blank" class="text-decoration-none text--secondary">
            Privacy Policy
          </a>
          •
          <a href="https://regentrenewables.com/terms-of-service" target="_blank" class="text-decoration-none text--secondary">
            Terms of Service
          </a>
          •
          <a href="https://regentrenewables.com/cookie-policy" target="_blank" class="text-decoration-none text--secondary">
            Cookie Policy
          </a>
        </span>
        <span class="d-none d-sm-inline">
          <a href="tel:+13478616017" class="text-decoration-none text--secondary">
            Phone: +1 347 861 6017
          </a>
        </span>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline } from '@mdi/js'
import NavMenu from '@/components/nav-menu/NavMenu.vue'
import Wallet from '@/components/wallet/Wallet.vue'
import ThemeSwitcher from '@/components/ThemeSwitcher.vue'
import UserMenu from '@/components/UserMenu.vue'

export default {
  components: {
    NavMenu,
    Wallet,
    ThemeSwitcher,
    UserMenu,
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
      },
    }
  },
  computed: {
    alertVisible: {
      get() {
        return this.$store.getters.alertVisible
      },
      set(value) {
        this.$store.commit('alertVisible', value)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
