import Vue from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js'
import auth0 from 'auth0-js'
import jwtDecode from 'jwt-decode'
import axios from 'axios'

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)

let instance

/** Returns the current instance of the SDK */
export const getInstance = () => instance

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
      }
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        ...options,
        client_id: options.clientId,
        redirect_uri: redirectUri,
      })

      try {
        // If the user is returning to the app after authentication..
        if (
          window.location.search.includes('code=') && window.location.search.includes('state=')
        ) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback()

          this.error = null

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState)
        }
      } catch (e) {
        this.error = e
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        this.user = await this.auth0Client.getUser()
        this.loading = false
      }
    },
    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup(options, config) {
        this.popupOpen = true

        try {
          await this.auth0Client.loginWithPopup(options, config)
          this.user = await this.auth0Client.getUser()
          this.isAuthenticated = await this.auth0Client.isAuthenticated()
          this.error = null
        } catch (e) {
          this.error = e
          // eslint-disable-next-line
          console.error(e)
        } finally {
          this.popupOpen = false
        }

        this.user = await this.auth0Client.getUser()
        this.isAuthenticated = true
      },
      /** Authenticates the user using a username and password */
      async loginWithCredentials({ username, password }, cb) {
        this.loading = true

        var auth0Authentication = new auth0.Authentication({
          domain: options.domain,
          clientID: options.clientId,
        })

        return auth0Authentication.login({
          realm: 'Username-Password-Authentication',
          username: username,
          password: password,
          audience: options.audience,
          scope: 'openid profile email',
        }, async (err, authResult) => {
          if (err) {
            this.error = err
            this.loading = false
            cb(err)
          } else {
            var decodedToken = {
              claims: jwtDecode(authResult.accessToken),
              user: jwtDecode(authResult.idToken),
            }

            await this.auth0Client.cacheManager.set({
              id_token: authResult.idToken,
              access_token: authResult.accessToken,
              expires_in: authResult.expiresIn,
              decodedToken: decodedToken,
              scope: authResult.scope,
              audience: options.audience || 'default',
              client_id: options.clientId,
            })

            this.auth0Client.cookieStorage.save('auth0.is.authenticated', true, {
              daysUntilExpire: this.auth0Client.sessionCheckExpiryDays,
            })

            this.user = await this.auth0Client.getUser()
            this.isAuthenticated = await this.auth0Client.isAuthenticated()
            this.error = null
            this.loading = false
            cb(null, this.user)
          }
        })
      },
      /** Update user information */
      async updateUser(user, cb) {
        var accessToken = await this.auth0Client.getTokenSilently()

        axios.post(process.env.VUE_APP_API + '/user/update', user, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }).then(async (response) => {
          await this.auth0Client.getTokenSilently({ ignoreCache: true })
          cb(null, response.data)
        }).catch((err) => {
          cb(err)
        })
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true
        try {
          await this.auth0Client.handleRedirectCallback()
          this.user = await this.auth0Client.getUser()
          this.isAuthenticated = true
          this.error = null
        } catch (e) {
          this.error = e
        } finally {
          this.loading = false
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o)
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o)
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o)
      },
      /** Gets the access token using a popup window */
      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o)
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        return this.auth0Client.logout(o)
      },
      isVerified(account) {
        return this.isAuthenticated && this.isBlockpassVerified() && this.isEthereumVerified(account)
      },
      isBlockpassVerified() {
        var verified
        if (this.isAuthenticated && this.user) {
          var app_metadata = this.user['https://namespace/app_metadata'] || {}
          var status = app_metadata.blockpass_status || 'unverified'
          verified = status === 'approved'
        }
        return verified
      },
      isEthereumVerified(account) {
        var verified
        if (this.isAuthenticated && this.user) {
          var app_metadata = this.user['https://namespace/app_metadata'] || {}
          var eth_accounts = app_metadata.eth_accounts || []
          if (account) {
            verified = eth_accounts.includes(account)
          } else {
            verified = eth_accounts.length > 0
          }
        }
        return verified
      },
    },
  })

  return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options)
  },
}
