import Web3 from 'web3'
import ERC20ABI from '@/web3/abis/ERC20.json'
import PartnershipFlipABI from '@/web3/abis/PartnershipFlip.json'

var install = function (Vue, options) {
  var infura_url = process.env.NODE_ENV === 'production' ? 'https://mainnet.infura.io/v3/' : 'https://ropsten.infura.io/v3/'
  var infura_id = '851c4a80bf94490d8c39c1cfb3946fb5'
  var web3 = new Web3(Web3.givenProvider || infura_url + infura_id)

  function toWei(value, decimals) {
    var unit = Number(decimals) === 6 ? 'mwei' : 'ether'
    return web3.utils.toWei(value, unit)
  }

  function fromWei(value, decimals) {
    var unit = Number(decimals) === 6 ? 'mwei' : 'ether'
    return web3.utils.fromWei(value, unit)
  }

  Object.defineProperty(Vue.prototype, '$web3', { value: web3 })
  Object.defineProperty(Vue.prototype, '$toWei', { value: toWei })
  Object.defineProperty(Vue.prototype, '$fromWei', { value: fromWei })
  Object.defineProperty(Vue.prototype, '$ERC20ABI', { value: ERC20ABI })
  Object.defineProperty(Vue.prototype, '$PartnershipFlipABI', { value: PartnershipFlipABI })
}

export default {
  install: install,
}
