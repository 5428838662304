<template>
  <v-icon ref="identicon" :key="wallet" left>
  </v-icon>
</template>

<script>
import jazzicon from '@metamask/jazzicon'

export default {
  props: {
    wallet: {
      type: String,
      default: undefined,
    },
  },
  mounted() {
    this.setIdenticon()
  },
  updated() {
    this.setIdenticon()
  },
  methods: {
    setIdenticon() {
      var seed = parseInt(this.wallet.slice(2, 10), 16)
      var identicon = jazzicon(18, seed)
      this.$refs.identicon.$el.append(identicon)
    },
  },
}
</script>
