const data = [
  {
    slug: 'the-daly-house',
    revenueSplitSeries: [
      {
        name: 'Amount',
        colorByPoint: true,
        innerSize: '50%',
        data: [
          {
            name: 'Revenue generated',
            y: 35,
          },
        ],
      },
    ],
    periodicRevenueSeries: [
      {
        name: 'Revenue Generated',
        data: [
          [new Date(2022, 9, 28, 2).getTime(), 35],
        ],
      },
    ],
    cumulativeRevenueSeries: [
      {
        name: 'Revenue Generated',
        data: [
          [new Date(2020, 9, 28, 2).getTime(), 35],
        ],
      },
    ],
  },
  {
    slug: 'sale-leaseback-test',
    revenueSplitSeries: [
      {
        name: 'Amount',
        colorByPoint: true,
        innerSize: '50%',
        data: [
          {
            name: 'Revenue generated',
            y: 1016.15,
          },
        ],
      },
    ],
    periodicRevenueSeries: [
      {
        name: 'Revenue Generated',
        data: [
          [new Date(2020, 10, 28, 2).getTime(), 27.29],
          [new Date(2020, 11, 28, 2).getTime(), 60.57],
          [new Date(2021, 0, 28, 2).getTime(), 65.23],
          [new Date(2021, 1, 28, 2).getTime(), 65.71],
          [new Date(2021, 2, 28, 2).getTime(), 61.55],
          [new Date(2021, 3, 28, 2).getTime(), 54.57],
          [new Date(2021, 4, 28, 2).getTime(), 39.82],
          [new Date(2021, 5, 28, 2).getTime(), 35.97],
          [new Date(2021, 6, 28, 2).getTime(), 41.77],
          [new Date(2021, 7, 28, 2).getTime(), 55.65],
          [new Date(2021, 8, 28, 2).getTime(), 52.08],
          [new Date(2021, 9, 28, 2).getTime(), 46.15],
          [new Date(2021, 10, 28, 2).getTime(), 43.39],
          [new Date(2021, 11, 28, 2).getTime(), 57.31],
          [new Date(2022, 0, 28, 2).getTime(), 68.15],
          [new Date(2022, 1, 28, 2).getTime(), 58.02],
          [new Date(2022, 2, 28, 2).getTime(), 48.05],
          [new Date(2022, 3, 28, 2).getTime(), 39.83],
          [new Date(2022, 4, 28, 2).getTime(), 32.16],
          [new Date(2022, 5, 28, 2).getTime(), 36.01],
          [new Date(2022, 6, 28, 2).getTime(), 27.68],
        ],
      },
    ],
    cumulativeRevenueSeries: [
      {
        name: 'Revenue Generated',
        data: [
          [new Date(2020, 10, 28, 2).getTime(), 27.29],
          [new Date(2020, 11, 28, 2).getTime(), 87.86],
          [new Date(2021, 0, 28, 2).getTime(), 153.09],
          [new Date(2021, 1, 28, 2).getTime(), 218.80],
          [new Date(2021, 2, 28, 2).getTime(), 280.35],
          [new Date(2021, 3, 28, 2).getTime(), 334.92],
          [new Date(2021, 4, 28, 2).getTime(), 374.74],
          [new Date(2021, 5, 28, 2).getTime(), 410.71],
          [new Date(2021, 6, 28, 2).getTime(), 452.48],
          [new Date(2021, 7, 28, 2).getTime(), 508.13],
          [new Date(2021, 8, 28, 2).getTime(), 560.21],
          [new Date(2021, 9, 28, 2).getTime(), 606.36],
          [new Date(2021, 10, 28, 2).getTime(), 649.75],
          [new Date(2021, 11, 28, 2).getTime(), 707.06],
          [new Date(2022, 0, 28, 2).getTime(), 775.20],
          [new Date(2022, 1, 28, 2).getTime(), 833.22],
          [new Date(2022, 2, 28, 2).getTime(), 881.27],
          [new Date(2022, 3, 28, 2).getTime(), 921.11],
          [new Date(2022, 4, 28, 2).getTime(), 953.27],
          [new Date(2022, 5, 28, 2).getTime(), 989.27],
          [new Date(2022, 6, 28, 2).getTime(), 1016.96],
        ],
      },
    ],
  },
  {
    slug: 'partnership-flip-test',
    revenueSplitSeries: [
      {
        name: 'Amount',
        colorByPoint: true,
        innerSize: '50%',
        data: [
          {
            name: 'Cash Flow',
            y: 525210,
          },
          {
            name: 'MACRS Depreciation',
            y: 778650,
          },
          {
            name: 'Investment Tax Credit',
            y: 232700,
          },
        ],
      },
    ],
    periodicRevenueSeries: [
      {
        name: 'Cash Flow',
        data: [
          [new Date(2022, 3, 28, 2).getTime(), 17212.262],
          [new Date(2022, 6, 28, 2).getTime(), 30121.4585],
          [new Date(2022, 9, 28, 2).getTime(), 34424.524],
          [new Date(2022, 12, 28, 2).getTime(), 4303.0655],
          [new Date(2023, 3, 28, 2).getTime(), 17091.75769],
          [new Date(2023, 6, 28, 2).getTime(), 29910.57595],
          [new Date(2023, 9, 28, 2).getTime(), 34183.51538],
          [new Date(2023, 12, 28, 2).getTime(), 4272.939422],
          [new Date(2024, 3, 28, 2).getTime(), 16979.95514],
          [new Date(2024, 6, 28, 2).getTime(), 29714.9215],
          [new Date(2024, 9, 28, 2).getTime(), 33959.91028],
          [new Date(2024, 12, 28, 2).getTime(), 4244.988785],
          [new Date(2025, 3, 28, 2).getTime(), 16876.6347],
          [new Date(2025, 6, 28, 2).getTime(), 29534.11072],
          [new Date(2025, 9, 28, 2).getTime(), 33753.26939],
          [new Date(2025, 12, 28, 2).getTime(), 4219.158674],
          [new Date(2026, 3, 28, 2).getTime(), 16781.58287],
          [new Date(2026, 6, 28, 2).getTime(), 29367.77002],
          [new Date(2026, 9, 28, 2).getTime(), 33563.16574],
          [new Date(2026, 12, 28, 2).getTime(), 4195.395717],
          [new Date(2027, 3, 28, 2).getTime(), 16694.59213],
          [new Date(2027, 6, 28, 2).getTime(), 29215.53622],
          [new Date(2027, 9, 28, 2).getTime(), 33389.18426],
          [new Date(2027, 12, 28, 2).getTime(), 4173.648032],
          [new Date(2028, 3, 28, 2).getTime(), 856.4670456],
          [new Date(2028, 6, 28, 2).getTime(), 1498.81733],
          [new Date(2028, 9, 28, 2).getTime(), 1712.934091],
          [new Date(2028, 12, 28, 2).getTime(), 214.1167614],
          [new Date(2029, 3, 28, 2).getTime(), 852.7831077],
          [new Date(2029, 6, 28, 2).getTime(), 1492.370439],
          [new Date(2029, 9, 28, 2).getTime(), 1705.566215],
          [new Date(2029, 12, 28, 2).getTime(), 213.1957769],
          [new Date(2030, 3, 28, 2).getTime(), 849.4843311],
          [new Date(2030, 6, 28, 2).getTime(), 1486.597579],
          [new Date(2030, 9, 28, 2).getTime(), 1698.968662],
          [new Date(2030, 12, 28, 2).getTime(), 212.3710828],
          [new Date(2031, 3, 28, 2).getTime(), 846.5611392],
          [new Date(2031, 6, 28, 2).getTime(), 1481.481994],
          [new Date(2031, 9, 28, 2).getTime(), 1693.122278],
          [new Date(2031, 12, 28, 2).getTime(), 211.6402848],
        ],
      },
      {
        name: 'MACRS Depreciation',
        data: [
          [new Date(2022, 12, 28, 2).getTime(), 155730],
          [new Date(2023, 12, 28, 2).getTime(), 249168],
          [new Date(2024, 12, 28, 2).getTime(), 149500.8],
          [new Date(2025, 12, 28, 2).getTime(), 89700.48],
          [new Date(2026, 12, 28, 2).getTime(), 89700.48],
          [new Date(2027, 12, 28, 2).getTime(), 44850.24],
        ],
      },
      {
        name: 'Investment Tax Credit',
        data: [
          [new Date(2022, 12, 28, 2).getTime(), 232700],
        ],
      },
    ],
    cumulativeRevenueSeries: [
      {
        name: 'Cash Flow',
        data: [
          [new Date(2022, 3, 28, 2).getTime(), 17212.262],
          [new Date(2022, 6, 28, 2).getTime(), 47333.7205],
          [new Date(2022, 9, 28, 2).getTime(), 81758.2445],
          [new Date(2022, 12, 28, 2).getTime(), 86061.31],
          [new Date(2023, 3, 28, 2).getTime(), 103153.0677],
          [new Date(2023, 6, 28, 2).getTime(), 133063.6436],
          [new Date(2023, 9, 28, 2).getTime(), 167247.159],
          [new Date(2023, 12, 28, 2).getTime(), 171520.0984],
          [new Date(2024, 3, 28, 2).getTime(), 188500.0536],
          [new Date(2024, 6, 28, 2).getTime(), 218214.9751],
          [new Date(2024, 9, 28, 2).getTime(), 252174.8854],
          [new Date(2024, 12, 28, 2).getTime(), 256419.8741],
          [new Date(2025, 3, 28, 2).getTime(), 273296.5088],
          [new Date(2025, 6, 28, 2).getTime(), 302830.6196],
          [new Date(2025, 9, 28, 2).getTime(), 336583.8889],
          [new Date(2025, 12, 28, 2).getTime(), 340803.0476],
          [new Date(2026, 3, 28, 2).getTime(), 357584.6305],
          [new Date(2026, 6, 28, 2).getTime(), 386952.4005],
          [new Date(2026, 9, 28, 2).getTime(), 420515.5663],
          [new Date(2026, 12, 28, 2).getTime(), 424710.962],
          [new Date(2027, 3, 28, 2).getTime(), 441405.5541],
          [new Date(2027, 6, 28, 2).getTime(), 470621.0903],
          [new Date(2027, 9, 28, 2).getTime(), 504010.2746],
          [new Date(2027, 12, 28, 2).getTime(), 508183.9226],
          [new Date(2028, 3, 28, 2).getTime(), 509040.3897],
          [new Date(2028, 6, 28, 2).getTime(), 510539.207],
          [new Date(2028, 9, 28, 2).getTime(), 512252.1411],
          [new Date(2028, 12, 28, 2).getTime(), 512466.2578],
          [new Date(2029, 3, 28, 2).getTime(), 513319.0409],
          [new Date(2029, 6, 28, 2).getTime(), 514811.4114],
          [new Date(2029, 9, 28, 2).getTime(), 516516.9776],
          [new Date(2029, 12, 28, 2).getTime(), 516730.1734],
          [new Date(2030, 3, 28, 2).getTime(), 517579.6577],
          [new Date(2030, 6, 28, 2).getTime(), 519066.2553],
          [new Date(2030, 9, 28, 2).getTime(), 520765.2239],
          [new Date(2030, 12, 28, 2).getTime(), 520977.595],
          [new Date(2031, 3, 28, 2).getTime(), 521824.1562],
          [new Date(2031, 6, 28, 2).getTime(), 523305.6382],
          [new Date(2031, 9, 28, 2).getTime(), 524998.7604],
          [new Date(2031, 12, 28, 2).getTime(), 525210.4007],
        ],
      },
      {
        name: 'MACRS Depreciation',
        data: [
          [new Date(2022, 3, 28, 2).getTime(), 0],
          [new Date(2022, 6, 28, 2).getTime(), 0],
          [new Date(2022, 9, 28, 2).getTime(), 0],
          [new Date(2022, 12, 28, 2).getTime(), 155730],
          [new Date(2023, 3, 28, 2).getTime(), 155730],
          [new Date(2023, 6, 28, 2).getTime(), 155730],
          [new Date(2023, 9, 28, 2).getTime(), 155730],
          [new Date(2023, 12, 28, 2).getTime(), 404898],
          [new Date(2024, 3, 28, 2).getTime(), 404898],
          [new Date(2024, 6, 28, 2).getTime(), 404898],
          [new Date(2024, 9, 28, 2).getTime(), 404898],
          [new Date(2024, 12, 28, 2).getTime(), 554398.8],
          [new Date(2025, 3, 28, 2).getTime(), 554398.8],
          [new Date(2025, 6, 28, 2).getTime(), 554398.8],
          [new Date(2025, 9, 28, 2).getTime(), 554398.8],
          [new Date(2025, 12, 28, 2).getTime(), 644099.28],
          [new Date(2026, 3, 28, 2).getTime(), 644099.28],
          [new Date(2026, 6, 28, 2).getTime(), 644099.28],
          [new Date(2026, 9, 28, 2).getTime(), 644099.28],
          [new Date(2026, 12, 28, 2).getTime(), 733799.76],
          [new Date(2027, 3, 28, 2).getTime(), 733799.76],
          [new Date(2027, 6, 28, 2).getTime(), 733799.76],
          [new Date(2027, 9, 28, 2).getTime(), 733799.76],
          [new Date(2027, 12, 28, 2).getTime(), 778650],
          [new Date(2028, 3, 28, 2).getTime(), 778650],
          [new Date(2028, 6, 28, 2).getTime(), 778650],
          [new Date(2028, 9, 28, 2).getTime(), 778650],
          [new Date(2028, 12, 28, 2).getTime(), 778650],
          [new Date(2029, 3, 28, 2).getTime(), 778650],
          [new Date(2029, 6, 28, 2).getTime(), 778650],
          [new Date(2029, 9, 28, 2).getTime(), 778650],
          [new Date(2029, 12, 28, 2).getTime(), 778650],
          [new Date(2030, 3, 28, 2).getTime(), 778650],
          [new Date(2030, 6, 28, 2).getTime(), 778650],
          [new Date(2030, 9, 28, 2).getTime(), 778650],
          [new Date(2030, 12, 28, 2).getTime(), 778650],
          [new Date(2031, 3, 28, 2).getTime(), 778650],
          [new Date(2031, 6, 28, 2).getTime(), 778650],
          [new Date(2031, 9, 28, 2).getTime(), 778650],
          [new Date(2031, 12, 28, 2).getTime(), 778650],
        ],
      },
      {
        name: 'Investment Tax Credit',
        data: [
          [new Date(2022, 3, 28, 2).getTime(), 0],
          [new Date(2022, 6, 28, 2).getTime(), 0],
          [new Date(2022, 9, 28, 2).getTime(), 0],
          [new Date(2022, 12, 28, 2).getTime(), 232700],
          [new Date(2023, 3, 28, 2).getTime(), 232700],
          [new Date(2023, 6, 28, 2).getTime(), 232700],
          [new Date(2023, 9, 28, 2).getTime(), 232700],
          [new Date(2023, 12, 28, 2).getTime(), 232700],
          [new Date(2024, 3, 28, 2).getTime(), 232700],
          [new Date(2024, 6, 28, 2).getTime(), 232700],
          [new Date(2024, 9, 28, 2).getTime(), 232700],
          [new Date(2024, 12, 28, 2).getTime(), 232700],
          [new Date(2025, 3, 28, 2).getTime(), 232700],
          [new Date(2025, 6, 28, 2).getTime(), 232700],
          [new Date(2025, 9, 28, 2).getTime(), 232700],
          [new Date(2025, 12, 28, 2).getTime(), 232700],
          [new Date(2026, 3, 28, 2).getTime(), 232700],
          [new Date(2026, 6, 28, 2).getTime(), 232700],
          [new Date(2026, 9, 28, 2).getTime(), 232700],
          [new Date(2026, 12, 28, 2).getTime(), 232700],
          [new Date(2027, 3, 28, 2).getTime(), 232700],
          [new Date(2027, 6, 28, 2).getTime(), 232700],
          [new Date(2027, 9, 28, 2).getTime(), 232700],
          [new Date(2027, 12, 28, 2).getTime(), 232700],
          [new Date(2028, 3, 28, 2).getTime(), 232700],
          [new Date(2028, 6, 28, 2).getTime(), 232700],
          [new Date(2028, 9, 28, 2).getTime(), 232700],
          [new Date(2028, 12, 28, 2).getTime(), 232700],
          [new Date(2029, 3, 28, 2).getTime(), 232700],
          [new Date(2029, 6, 28, 2).getTime(), 232700],
          [new Date(2029, 9, 28, 2).getTime(), 232700],
          [new Date(2029, 12, 28, 2).getTime(), 232700],
          [new Date(2030, 3, 28, 2).getTime(), 232700],
          [new Date(2030, 6, 28, 2).getTime(), 232700],
          [new Date(2030, 9, 28, 2).getTime(), 232700],
          [new Date(2030, 12, 28, 2).getTime(), 232700],
          [new Date(2031, 3, 28, 2).getTime(), 232700],
          [new Date(2031, 6, 28, 2).getTime(), 232700],
          [new Date(2031, 9, 28, 2).getTime(), 232700],
          [new Date(2031, 12, 28, 2).getTime(), 232700],
        ],
      },
    ],
  },
  {
    slug: 'big-partnership-flip-test',
    revenueSplitSeries: [
      {
        name: 'Amount',
        colorByPoint: true,
        innerSize: '50%',
        data: [
          {
            name: 'Cash Flow',
            y: 525210,
          },
          {
            name: 'MACRS Depreciation',
            y: 778650,
          },
          {
            name: 'Investment Tax Credit',
            y: 232700,
          },
        ],
      },
    ],
    periodicRevenueSeries: [
      {
        name: 'Cash Flow',
        data: [
          [new Date(2022, 3, 28, 2).getTime(), 17212.262],
          [new Date(2022, 6, 28, 2).getTime(), 30121.4585],
          [new Date(2022, 9, 28, 2).getTime(), 34424.524],
          [new Date(2022, 12, 28, 2).getTime(), 4303.0655],
          [new Date(2023, 3, 28, 2).getTime(), 17091.75769],
          [new Date(2023, 6, 28, 2).getTime(), 29910.57595],
          [new Date(2023, 9, 28, 2).getTime(), 34183.51538],
          [new Date(2023, 12, 28, 2).getTime(), 4272.939422],
          [new Date(2024, 3, 28, 2).getTime(), 16979.95514],
          [new Date(2024, 6, 28, 2).getTime(), 29714.9215],
          [new Date(2024, 9, 28, 2).getTime(), 33959.91028],
          [new Date(2024, 12, 28, 2).getTime(), 4244.988785],
          [new Date(2025, 3, 28, 2).getTime(), 16876.6347],
          [new Date(2025, 6, 28, 2).getTime(), 29534.11072],
          [new Date(2025, 9, 28, 2).getTime(), 33753.26939],
          [new Date(2025, 12, 28, 2).getTime(), 4219.158674],
          [new Date(2026, 3, 28, 2).getTime(), 16781.58287],
          [new Date(2026, 6, 28, 2).getTime(), 29367.77002],
          [new Date(2026, 9, 28, 2).getTime(), 33563.16574],
          [new Date(2026, 12, 28, 2).getTime(), 4195.395717],
          [new Date(2027, 3, 28, 2).getTime(), 16694.59213],
          [new Date(2027, 6, 28, 2).getTime(), 29215.53622],
          [new Date(2027, 9, 28, 2).getTime(), 33389.18426],
          [new Date(2027, 12, 28, 2).getTime(), 4173.648032],
          [new Date(2028, 3, 28, 2).getTime(), 856.4670456],
          [new Date(2028, 6, 28, 2).getTime(), 1498.81733],
          [new Date(2028, 9, 28, 2).getTime(), 1712.934091],
          [new Date(2028, 12, 28, 2).getTime(), 214.1167614],
          [new Date(2029, 3, 28, 2).getTime(), 852.7831077],
          [new Date(2029, 6, 28, 2).getTime(), 1492.370439],
          [new Date(2029, 9, 28, 2).getTime(), 1705.566215],
          [new Date(2029, 12, 28, 2).getTime(), 213.1957769],
          [new Date(2030, 3, 28, 2).getTime(), 849.4843311],
          [new Date(2030, 6, 28, 2).getTime(), 1486.597579],
          [new Date(2030, 9, 28, 2).getTime(), 1698.968662],
          [new Date(2030, 12, 28, 2).getTime(), 212.3710828],
          [new Date(2031, 3, 28, 2).getTime(), 846.5611392],
          [new Date(2031, 6, 28, 2).getTime(), 1481.481994],
          [new Date(2031, 9, 28, 2).getTime(), 1693.122278],
          [new Date(2031, 12, 28, 2).getTime(), 211.6402848],
        ],
      },
      {
        name: 'MACRS Depreciation',
        data: [
          [new Date(2022, 12, 28, 2).getTime(), 155730],
          [new Date(2023, 12, 28, 2).getTime(), 249168],
          [new Date(2024, 12, 28, 2).getTime(), 149500.8],
          [new Date(2025, 12, 28, 2).getTime(), 89700.48],
          [new Date(2026, 12, 28, 2).getTime(), 89700.48],
          [new Date(2027, 12, 28, 2).getTime(), 44850.24],
        ],
      },
      {
        name: 'Investment Tax Credit',
        data: [
          [new Date(2022, 12, 28, 2).getTime(), 232700],
        ],
      },
    ],
    cumulativeRevenueSeries: [
      {
        name: 'Cash Flow',
        data: [
          [new Date(2022, 3, 28, 2).getTime(), 17212.262],
          [new Date(2022, 6, 28, 2).getTime(), 47333.7205],
          [new Date(2022, 9, 28, 2).getTime(), 81758.2445],
          [new Date(2022, 12, 28, 2).getTime(), 86061.31],
          [new Date(2023, 3, 28, 2).getTime(), 103153.0677],
          [new Date(2023, 6, 28, 2).getTime(), 133063.6436],
          [new Date(2023, 9, 28, 2).getTime(), 167247.159],
          [new Date(2023, 12, 28, 2).getTime(), 171520.0984],
          [new Date(2024, 3, 28, 2).getTime(), 188500.0536],
          [new Date(2024, 6, 28, 2).getTime(), 218214.9751],
          [new Date(2024, 9, 28, 2).getTime(), 252174.8854],
          [new Date(2024, 12, 28, 2).getTime(), 256419.8741],
          [new Date(2025, 3, 28, 2).getTime(), 273296.5088],
          [new Date(2025, 6, 28, 2).getTime(), 302830.6196],
          [new Date(2025, 9, 28, 2).getTime(), 336583.8889],
          [new Date(2025, 12, 28, 2).getTime(), 340803.0476],
          [new Date(2026, 3, 28, 2).getTime(), 357584.6305],
          [new Date(2026, 6, 28, 2).getTime(), 386952.4005],
          [new Date(2026, 9, 28, 2).getTime(), 420515.5663],
          [new Date(2026, 12, 28, 2).getTime(), 424710.962],
          [new Date(2027, 3, 28, 2).getTime(), 441405.5541],
          [new Date(2027, 6, 28, 2).getTime(), 470621.0903],
          [new Date(2027, 9, 28, 2).getTime(), 504010.2746],
          [new Date(2027, 12, 28, 2).getTime(), 508183.9226],
          [new Date(2028, 3, 28, 2).getTime(), 509040.3897],
          [new Date(2028, 6, 28, 2).getTime(), 510539.207],
          [new Date(2028, 9, 28, 2).getTime(), 512252.1411],
          [new Date(2028, 12, 28, 2).getTime(), 512466.2578],
          [new Date(2029, 3, 28, 2).getTime(), 513319.0409],
          [new Date(2029, 6, 28, 2).getTime(), 514811.4114],
          [new Date(2029, 9, 28, 2).getTime(), 516516.9776],
          [new Date(2029, 12, 28, 2).getTime(), 516730.1734],
          [new Date(2030, 3, 28, 2).getTime(), 517579.6577],
          [new Date(2030, 6, 28, 2).getTime(), 519066.2553],
          [new Date(2030, 9, 28, 2).getTime(), 520765.2239],
          [new Date(2030, 12, 28, 2).getTime(), 520977.595],
          [new Date(2031, 3, 28, 2).getTime(), 521824.1562],
          [new Date(2031, 6, 28, 2).getTime(), 523305.6382],
          [new Date(2031, 9, 28, 2).getTime(), 524998.7604],
          [new Date(2031, 12, 28, 2).getTime(), 525210.4007],
        ],
      },
      {
        name: 'MACRS Depreciation',
        data: [
          [new Date(2022, 3, 28, 2).getTime(), 0],
          [new Date(2022, 6, 28, 2).getTime(), 0],
          [new Date(2022, 9, 28, 2).getTime(), 0],
          [new Date(2022, 12, 28, 2).getTime(), 155730],
          [new Date(2023, 3, 28, 2).getTime(), 155730],
          [new Date(2023, 6, 28, 2).getTime(), 155730],
          [new Date(2023, 9, 28, 2).getTime(), 155730],
          [new Date(2023, 12, 28, 2).getTime(), 404898],
          [new Date(2024, 3, 28, 2).getTime(), 404898],
          [new Date(2024, 6, 28, 2).getTime(), 404898],
          [new Date(2024, 9, 28, 2).getTime(), 404898],
          [new Date(2024, 12, 28, 2).getTime(), 554398.8],
          [new Date(2025, 3, 28, 2).getTime(), 554398.8],
          [new Date(2025, 6, 28, 2).getTime(), 554398.8],
          [new Date(2025, 9, 28, 2).getTime(), 554398.8],
          [new Date(2025, 12, 28, 2).getTime(), 644099.28],
          [new Date(2026, 3, 28, 2).getTime(), 644099.28],
          [new Date(2026, 6, 28, 2).getTime(), 644099.28],
          [new Date(2026, 9, 28, 2).getTime(), 644099.28],
          [new Date(2026, 12, 28, 2).getTime(), 733799.76],
          [new Date(2027, 3, 28, 2).getTime(), 733799.76],
          [new Date(2027, 6, 28, 2).getTime(), 733799.76],
          [new Date(2027, 9, 28, 2).getTime(), 733799.76],
          [new Date(2027, 12, 28, 2).getTime(), 778650],
          [new Date(2028, 3, 28, 2).getTime(), 778650],
          [new Date(2028, 6, 28, 2).getTime(), 778650],
          [new Date(2028, 9, 28, 2).getTime(), 778650],
          [new Date(2028, 12, 28, 2).getTime(), 778650],
          [new Date(2029, 3, 28, 2).getTime(), 778650],
          [new Date(2029, 6, 28, 2).getTime(), 778650],
          [new Date(2029, 9, 28, 2).getTime(), 778650],
          [new Date(2029, 12, 28, 2).getTime(), 778650],
          [new Date(2030, 3, 28, 2).getTime(), 778650],
          [new Date(2030, 6, 28, 2).getTime(), 778650],
          [new Date(2030, 9, 28, 2).getTime(), 778650],
          [new Date(2030, 12, 28, 2).getTime(), 778650],
          [new Date(2031, 3, 28, 2).getTime(), 778650],
          [new Date(2031, 6, 28, 2).getTime(), 778650],
          [new Date(2031, 9, 28, 2).getTime(), 778650],
          [new Date(2031, 12, 28, 2).getTime(), 778650],
        ],
      },
      {
        name: 'Investment Tax Credit',
        data: [
          [new Date(2022, 3, 28, 2).getTime(), 0],
          [new Date(2022, 6, 28, 2).getTime(), 0],
          [new Date(2022, 9, 28, 2).getTime(), 0],
          [new Date(2022, 12, 28, 2).getTime(), 232700],
          [new Date(2023, 3, 28, 2).getTime(), 232700],
          [new Date(2023, 6, 28, 2).getTime(), 232700],
          [new Date(2023, 9, 28, 2).getTime(), 232700],
          [new Date(2023, 12, 28, 2).getTime(), 232700],
          [new Date(2024, 3, 28, 2).getTime(), 232700],
          [new Date(2024, 6, 28, 2).getTime(), 232700],
          [new Date(2024, 9, 28, 2).getTime(), 232700],
          [new Date(2024, 12, 28, 2).getTime(), 232700],
          [new Date(2025, 3, 28, 2).getTime(), 232700],
          [new Date(2025, 6, 28, 2).getTime(), 232700],
          [new Date(2025, 9, 28, 2).getTime(), 232700],
          [new Date(2025, 12, 28, 2).getTime(), 232700],
          [new Date(2026, 3, 28, 2).getTime(), 232700],
          [new Date(2026, 6, 28, 2).getTime(), 232700],
          [new Date(2026, 9, 28, 2).getTime(), 232700],
          [new Date(2026, 12, 28, 2).getTime(), 232700],
          [new Date(2027, 3, 28, 2).getTime(), 232700],
          [new Date(2027, 6, 28, 2).getTime(), 232700],
          [new Date(2027, 9, 28, 2).getTime(), 232700],
          [new Date(2027, 12, 28, 2).getTime(), 232700],
          [new Date(2028, 3, 28, 2).getTime(), 232700],
          [new Date(2028, 6, 28, 2).getTime(), 232700],
          [new Date(2028, 9, 28, 2).getTime(), 232700],
          [new Date(2028, 12, 28, 2).getTime(), 232700],
          [new Date(2029, 3, 28, 2).getTime(), 232700],
          [new Date(2029, 6, 28, 2).getTime(), 232700],
          [new Date(2029, 9, 28, 2).getTime(), 232700],
          [new Date(2029, 12, 28, 2).getTime(), 232700],
          [new Date(2030, 3, 28, 2).getTime(), 232700],
          [new Date(2030, 6, 28, 2).getTime(), 232700],
          [new Date(2030, 9, 28, 2).getTime(), 232700],
          [new Date(2030, 12, 28, 2).getTime(), 232700],
          [new Date(2031, 3, 28, 2).getTime(), 232700],
          [new Date(2031, 6, 28, 2).getTime(), 232700],
          [new Date(2031, 9, 28, 2).getTime(), 232700],
          [new Date(2031, 12, 28, 2).getTime(), 232700],
        ],
      },
    ],
  },
]

export default data
