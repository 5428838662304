<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        size="40px"
        v-bind="attrs"
        v-on="on"
      >
        <img :src="$auth.user.picture" referrerpolicy="no-referrer">
      </v-avatar>
    </template>
    <v-list>
      <div v-if="$auth.user" class="pb-3 pt-2 px-3">
        <v-avatar size="40px">
          <img :src="$auth.user.picture" referrerpolicy="no-referrer">
        </v-avatar>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ $auth.user.name }}
          </span>
        </div>
      </div>

      <v-divider v-if="$auth.user"></v-divider>

      <!-- Profile -->
      <v-list-item :to="{ name: 'profile' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccount }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Settings -->
      <v-list-item :to="{ name: 'verification' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiShieldCheck }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Verification</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Notifications -->
      <v-list-item :to="{ name: 'notifications' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiBellOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Notifications</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item link @click="logout">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Log Out</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccount,
  mdiBellOutline,
  mdiShieldCheck,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAccount,
        mdiBellOutline,
        mdiShieldCheck,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
  data() {
    return {
      user: undefined,
    }
  },
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
