import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import projects from './projects'
import data from './data'
import support from './support'
import urls from './urls'
import chains from './chains'

var env = process.env.VUE_APP_ENV
// var env = 'staging'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: undefined,
    chainId: undefined,
    wallet: undefined,
    projects: projects,
    data: data,
    contracts: [],
    alertVisible: false,
    alertType: 'error',
    alertText: '',
    transactions: {
      items: [],
      loaded: false,
      filters: [],
    },
    investments: {
      items: [],
      loaded: false,
    },
    support: {
      items: support,
      open: [],
      filter: undefined,
    },
    etherscanURL: process.env.NODE_ENV === 'production' ? urls['0x1'] : urls['0x3'],
  },
  mutations: {
    user(state, user) {
      state.user = user
    },
    chainId(state, chainId) {
      state.chainId = chainId
      state.etherscanURL = urls[chainId]
    },
    wallet(state, wallet) {
      state.wallet = wallet

      state.transactions.items = []
      state.transactions.loaded = false

      state.investments.items = []
      state.investments.loaded = false
    },
    setContract(state, item) {
      var index = state.contracts.findIndex(c => c.address === item.address)
      if (index === -1) {
        state.contracts.push(item)
      } else {
        state.contracts[index] = item
      }
    },
    setProjectEnergy(state, payload) {
      var project = state.projects.filter(project => project.inverterID === payload.inverterID && project.env === env)[0]
      project.energyGenerated = payload.data
    },
    alertVisible(state, text) {
      state.alertVisible = text
    },
    alertType(state, type) {
      state.alertType = type
    },
    alertText(state, type) {
      state.alertText = type
      state.alertVisible = true
    },
    transactionsItems(state, items) {
      state.transactions.items = items
    },
    transactionsLoaded(state) {
      state.transactions.loaded = true
    },
    transactionsClear(state) {
      state.transactions.items = []
      state.transactions.loaded = false
    },
    transactionsFilters(state, filters) {
      state.transactions.filters = filters
    },
    investmentsAddItem(state, item) {
      state.investments.items.push(item)
    },
    investmentsLoaded(state) {
      state.investments.loaded = true
    },
    investmentsClear(state) {
      state.investments.items = []
      state.investments.loaded = false
    },
    supportFilter(state, val) {
      state.support.filter = val
    },
    supportOpen(state, val) {
      state.support.open = val
    },
  },
  getters: {
    user: (state) => state.user,
    chainId: (state) => state.chainId,
    wallet: (state) => state.wallet,
    projects: (state) => {
      var projects = state.projects.filter((project) => {
        return project.env === env
      })
      return projects
    },
    projectsStatus: (state) => (status) => {
      var projects = state.projects.filter((project) => {
        return project.env === env && project.status === status
      })
      return projects
    },
    projectsChain: (state) => {
      var projects = state.projects.filter((project) => {
        return project.env === env && project.chain_id === state.chainId
      })
      return projects
    },
    getProject: (state) => (slug) => {
      return state.projects.filter(project => project.slug === slug && project.env === env)[0]
    },
    getProjectData: (state) => (slug) => {
      return state.data.filter(d => d.slug === slug)[0]
    },
    getProjectEnergy: (state) => (inverterID) => {
      var projects = state.projects.filter(project => project.inverterID === inverterID && project.env === env)
      return projects[0] ? projects[0].energyGenerated : null
    },
    getStablecoinAddress: (state, getters) => {
      var projects = getters.projectsChain
      return projects[0] ? projects[0].stablecoinAddress : null
    },
    getContract: (state) => (address) => {
      return state.contracts.filter(contract => contract.address === address)[0]
    },
    alertVisible: (state) => state.alertVisible,
    alertType: (state) => state.alertType,
    alertText: (state) => state.alertText,
    transactionsItems: (state) => state.transactions.items,
    transactionsLoaded: (state) => state.transactions.loaded,
    transactionsFilters: (state) => state.transactions.filters,
    investmentsItems: (state) => state.investments.items,
    investmentsLoaded: (state) => state.investments.loaded,
    supportItems: (state) => state.support.items,
    supportOpen: (state) => state.support.open,
    supportFilter: (state) => state.support.filter,
    etherscanURL: (state) => state.etherscanURL,
    platformFor: () => (chainId) => chains[chainId].platform,
    platformName: () => (chainId) => chains[chainId].name,
    platformFullname: () => (chainId) => chains[chainId].fullname,
    explorerUrlFor: () => (chainId) => chains[chainId].explorer,
    rpcUrlFor: () => (chainId) => chains[chainId].rpc_url,
    symbolFor: () => (chainId) => chains[chainId].symbol,
  },
  actions: {
    loadContract (context, address) {
      return new Promise((resolve, reject) => {
        var contract = context.getters.getContract(address)

        if (contract && contract.name) {
          resolve(contract)
        } else {
          var web3 = this._vm.$web3
          var PartnershipFlipABI = this._vm.$PartnershipFlipABI

          var instance = new web3.eth.Contract(PartnershipFlipABI, address)

          instance.methods.info().call((error, result) => {
            if (!error) {
              var contract = {
                address: address,
                name: result.name,
                symbol: result.symbol,
                decimals: result.decimals,
                admin: result.admin,
                owner: result.owner,
                stablecoin: result.stablecoin,
                stablecoinSymbol: result.stablecoinSymbol,
                stablecoinDecimals: result.stablecoinDecimals,
                investmentFee: web3.utils.fromWei(result.investmentFee) + '%',
                distributionFee: web3.utils.fromWei(result.distributionFee) + '%',
                TEPPreFlipShare: web3.utils.fromWei(result.TEPPreFlipShare) + '%',
                TEPPostFlipShare: web3.utils.fromWei(result.TEPPostFlipShare) + '%',
                TEPInvestmentShare: web3.utils.fromWei(result.TEPInvestmentShare) + '%',
                CEPPreFlipShare: web3.utils.fromWei(result.CEPPreFlipShare) + '%',
                CEPPostFlipShare: web3.utils.fromWei(result.CEPPostFlipShare) + '%',
                CEPInvestmentShare: web3.utils.fromWei(result.CEPInvestmentShare) + '%',
                flipped: result.flipped,
              }
              context.commit('setContract', contract)
              resolve(contract)
            } else {
              reject(error)
            }
          })
        }
      })
    },
    loadProjectEnergy (context, inverterID) {
      return new Promise((resolve, reject) => {
        var energy = context.getters.getProjectEnergy(inverterID)
        if (energy == null) {
          var url = process.env.VUE_APP_API.replace('api', 'data') + '/energy/' + inverterID
          axios.get(url).then((response) => {
            context.commit('setProjectEnergy', { inverterID, data: response.data })
            resolve(response.data)
          }).catch((err) => {
            reject(err)
          })
        } else {
          resolve(energy)
        }
      })
    },
  },
  modules: {},
})
