const data = {
  '0x1': {
    name: 'Ethereum',
    fullname: 'Ethereum Mainnet',
    platform: 'eth',
    symbol: 'ETH',
    explorer: 'https://etherscan.io',
    rpc_url: 'https://mainnet.infura.io/v3',
  },
  '0x3': {
    name: 'Ropsten',
    fullname: 'Ropsten Testnet',
    platform: 'eth',
    symbol: 'ETH',
    explorer: 'https://ropsten.etherscan.io',
    rpc_url: 'https://ropsten.infura.io/v3',
  },
  '0x4': {
    name: 'Rinkeby',
    fullname: 'Rinkeby Testnet',
    platform: 'eth',
    symbol: 'ETH',
    explorer: 'https://rinkeby.etherscan.io',
    rpc_url: 'https://rinkeby.infura.io/v3',
  },
  '0x5': {
    name: 'Goerli',
    fullname: 'Goerli Testnet',
    platform: 'eth',
    symbol: 'ETH',
    explorer: 'https://goerli.etherscan.io',
    rpc_url: 'https://goerli.infura.io/v3',
  },
  '0x2a': {
    name: 'Kovan',
    fullname: 'Kovan Testnet',
    platform: 'eth',
    symbol: 'ETH',
    explorer: 'https://kovan.etherscan.io',
    rpc_url: 'https://kovan.infura.io/v3',
  },
  '0x38': {
    name: 'BSC',
    fullname: 'Binance Smart Chain Mainnet',
    platform: 'bsc',
    symbol: 'BNB',
    explorer: 'https://bscscan.com',
    rpc_url: 'https://bsc-dataseed1.binance.org',
  },
  '0x61': {
    name: 'BSC Testnet',
    fullname: 'Binance Smart Chain Testnet',
    platform: 'bsc',
    symbol: 'BNB',
    explorer: 'https://testnet.bscscan.com',
    rpc_url: 'https://data-seed-prebsc-1-s1.binance.org:8545',
  },
  '0x89': {
    name: 'Polygon',
    fullname: 'Polygon Mainnet',
    platform: 'pol',
    symbol: 'MATIC',
    explorer: 'https://polygonscan.com',
    rpc_url: 'https://polygon-rpc.com',
  },
  '0x13881': {
    name: 'Mumbai',
    fullname: 'Polygon Mumbai',
    platform: 'pol',
    symbol: 'MATIC',
    explorer: 'https://mumbai.polygonscan.com',
    rpc_url: 'https://rpc-mumbai.maticvigil.com',
  },
  '0xfa': {
    name: 'Fantom',
    fullname: 'Fantom Mainnet',
    platform: 'fan',
    symbol: 'FTM',
    explorer: 'https://ftmscan.com',
    rpc_url: 'https://rpc.ftm.tools',
  },
  '0xa86a': {
    name: 'Avalanche C-Chain',
    fullname: 'Avalanche C-Chain Mainnet',
    platform: 'ava',
    symbol: 'AVAX',
    explorer: 'https://snowtrace.io',
    rpc_url: 'https://api.avax.network/ext/bc/C/rpc',
  },
  '0xa869': {
    name: 'Avalanche Fuji Testnet',
    fullname: 'Avalanche Fuji Testnet',
    platform: 'ava',
    symbol: 'AVAX',
    explorer: 'https://testnet.snowtrace.io/',
    rpc_url: 'https://api.avax-test.network/ext/bc/C/rpc',
  },
  '0xf6': {
    name: 'Energy Web',
    fullname: 'Energy Web Chain',
    platform: 'ewc',
    symbol: 'EWC',
    explorer: 'https://explorer.energyweb.org',
    rpc_url: 'https://rpc.energyweb.org',
  },
  '0x12047': {
    name: 'Volta Testnet',
    fullname: 'Energy Web Volta Testnet',
    platform: 'ewc',
    symbol: 'EWC',
    explorer: 'https://volta-explorer.energyweb.org',
    rpc_url: 'https://volta-rpc.energyweb.org',
  },
  '0x539': {
    name: 'Localhost',
    fullname: 'Localhost',
    platform: 'eth',
    symbol: 'ETH',
    explorer: 'http://localhost:8000/#',
    rpc_url: 'http://localhost:8545',
  },
}

export default data
