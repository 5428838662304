import colors from 'vuetify/lib/util/colors'

require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#123850', // Regent blue
        secondary: '#1f7e71', // Regent teal darken
        tertiary: '#ffdc7c', // Regent yellow
        accent: '#143f5a', // Regent blue lighten
        success: '#56CA00', // Green
        info: '#16B1FF', // Blue
        warning: '#FFB400', // Yellow
        error: '#FF4C51', // Red
        background: '#f4f5fa', // White darken
        background_light: '#fbfbfd', // White darken
      },
      dark: {
        primary: '#ffffff', // White
        secondary: '#abded7', // Regent teal
        tertiary: '#ffdc7c', // Regent yellow
        accent: '#123850', // Regent blue
        success: '#56CA00', // Green
        info: '#16B1FF', // Blue
        warning: '#FFB400', // Yellow
        error: '#FF4C51', // Red
        background: '#123850', // Regent blue
        background_light: '#164461', // Regent blue lighten
      },
    },
  },
}
