import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import LoadScript from 'vue-plugin-load-script'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import web3 from './web3'

// Import the plugin here
import { Auth0Plugin } from './auth'

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  cacheLocation: 'localstorage',
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    )
  },
})

Vue.use(LoadScript)
Vue.use(web3)

Vue.filter('toNumber', (value) => {
  if (Number.isNaN(parseFloat(value))) {
    return value
  }
  var formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return formatter.format(value)
})

Vue.filter('toCurrency', (value) => {
  if (Number.isNaN(parseFloat(value))) {
    return value
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return formatter.format(value)
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
