const data = [
  {
    question: 'Which currencies can I use to invest?',
    answer: 'You need to use USD Coin (USDC) which is a US dollar backed stablecoin on the Ethereum network.',
  },
  {
    question: 'Which wallets do you support?',
    answer: 'We currently support MetaMask and are planning to add support for more wallets in the future.',
  },
  {
    question: 'How do investments work?',
    answer: 'When you send USD Coin to the project contract address you will receive tokens in return. The tokens represent your share of the project.',
  },
  {
    question: 'How do distributions work?',
    answer: 'When a project has made a profit it will distribute the profit to all token holders according to their share of the project.',
  },
  {
    question: 'How do partnership flip projects work?',
    answer: 'In a partnership flip the investors start off with majority ownership (about 97%). When the project has reached certain criteria the majority ownership flips to the developer.',
  },
  {
    question: 'How do sale leaseback projects work?',
    answer: 'In a sale leaseback project the investors own the project and receive regular rental payments.',
  },
  {
    question: 'What is the minimum investment amount?',
    answer: 'The minimum investment amount depends on the project.',
  },
  {
    question: 'What is the maximum investment amount?',
    answer: 'The maximum investment amount depends on the project.',
  },
  {
    question: 'How long is the lifetime of a project?',
    answer: 'It can be from 20 to 30 years or even longer.',
  },
  {
    question: 'Can I sell my tokens after I have invested in a project?',
    answer: 'Yes you can sell or transfer your tokens to another wallet.',
  },
]

export default data
